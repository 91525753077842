<template>
  <b-card>
    <div>
      <b-overlay :show="showChangeKYCOverlay" rounded="sm" variant="dark">
        <div>
          <!-- Header: id card -->
          <div class="mb-2">
            <h4 class="mb-1 ml-50">
              <feather-icon icon="CreditCardIcon" size="19" /> Recognized
              Government ID Card
              <feather-icon icon="CheckIcon" size="20" class="text-success" v-if="generalData.identification_file != null" />
            </h4>

            <b-form-group label="Identification Type" label-for="language">
              <v-select
                v-model="identification_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="idOptions"
                :clearable="false"
                input-id="language"
              />
            </b-form-group>

            <label for="extension" class=""
              >Allowed JPG or PNG. Max size of 800kB</label
            >
            <b-form-file
              accept=".jpg, .png"
              @change="selectIdentificationFile"
            />
          </div>

          <!-- Header: passport -->
          <div class="mb-1 mt-2">
            <h4 class="mb-0 ml-50">
              <feather-icon icon="CameraIcon" size="19" /> Passport Photograph
              <feather-icon icon="CheckIcon" size="20" class="text-success" v-if="generalData.passport_file != null" />
            </h4>
            <p>
              <!-- <small>()</small> -->
            </p>
          </div>

          <!-- media -->
          <b-media no-body class="mb-2">
            <b-media-aside>
              <b-link>
                <b-img
                  ref="kpreviewEl"
                  rounded
                  :src="generalData.passport_file"
                  height="80"
                />
              </b-link>
              <!--/ avatar -->
            </b-media-aside>

            <b-media-body class="mt-75 ml-75">
              <!-- upload button -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.krefInputEl.$el.click()"
              >
                Select Passport
              </b-button>
              <b-form-file
                ref="krefInputEl"
                accept=".jpg, .png"
                :hidden="true"
                plain
                @input="inputImageRenderer"
                @change="selectPassportFile"
              />
              <!--/ upload button -->

              <!-- reset -->
              <!-- <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
        >
          Reset
        </b-button> -->
              <!--/ reset -->
              <b-card-text>Allowed JPG or PNG. Max size of 800kB</b-card-text>
            </b-media-body>
          </b-media>
          <!--/ media -->

          <!-- Header: cac docs -->
          <div class="mb-1 mt-2">
            <h4 class="mb-0 ml-50">
              <feather-icon icon="FileTextIcon" size="19" /> CAC Documents
              <small>(Optional)</small>
              <feather-icon icon="CheckIcon" size="20" class="text-success" v-if="generalData.cac_file != null" />
            </h4>
            <label for="extension" class=""
              >Allowed PDF. Max size of 800kB</label
            >
            <b-form-file accept=".pdf" @change="selectCACFile" />
          </div>

          <!-- form -->
          <b-form @submit="onSubmit" @reset="onReset">
            <b-row> </b-row>
            <b-row>
              <!-- buttons -->
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-1 mr-1"
                  type="submit"
                >
                  Upload KYC Documents
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  class="mt-1"
                >
                  Reset
                </b-button>
              </b-col>
              <!--/ buttons -->
            </b-row>
          </b-form>
        </div>
      </b-overlay>
    </div>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormFile,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
import { apiUrl } from "@/constants/config";
import store from "@/store";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormFile,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BOverlay,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      //optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
      identification_type: this.idOptions[0],
      identification_file: "",
      passport_file: "",
      cac_file: "",
      showChangeKYCOverlay: false,
    };
  },
  computed: {},
  methods: {
    selectIdentificationFile(event) {
      this.identification_file = event.target.files[0];
    },
    selectCACFile(event) {
      this.cac_file = event.target.files[0];
    },
    selectPassportFile(event) {
      this.passport_file = event.target.files[0];
    },
    resetForm() {
      //this.optionsLocal = JSON.parse(JSON.stringify(this.generalData));
    },
    onSubmit(event) {
      this.showChangeKYCOverlay = true;
      event.preventDefault();
      const data = new FormData();
      data.append("identification_type", this.identification_type);
      data.append("identification_file", this.identification_file);
      data.append("passport_file", this.passport_file);
      data.append("cac_file", this.cac_file);
      data.append("_method", "PATCH");
      axios({
        url: apiUrl + `kyc`,
        data: data,
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + store.state.user.currentUser.token,
        },
      })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success: " + response.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.onReset(event);
          this.$router.push("/");
        })
        .catch((error) => {
          console.log(error.response.data.message);
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                "Error: " +
                (error.response.status == 422
                  ? error.response.data.message
                  : error.message),
              icon: "EditIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.showChangeKYCOverlay = false;
        });
    },
    onReset(event) {
      event.preventDefault();
      this.identification_file = "";
      this.passport_file = "";
      this.cac_file = "";
    },
  },
  setup(props) {
    const krefInputEl = ref(null);
    const kpreviewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      krefInputEl,
      (base64) => {
        props.generalData.passport_file = base64;
      }
    );

    const idOptions = [
      "NIN",
      "VOTER'S CARD",
      "INT'L PASSPORT",
      "DRIVER'S LICENSE",
    ];

    return {
      idOptions,
      krefInputEl,
      kpreviewEl,
      inputImageRenderer,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
