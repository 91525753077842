<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general
        v-if="options.user"
        :user-data="options.user"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- KYC tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="FileIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">KYC</span>
      </template>

      <know-your-customer :general-data="options.user" />
    </b-tab>
    <!--/ KYC tab -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import store from '@/store/index'
import { mapActions } from 'vuex'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './ChangePassword.vue'
import KnowYourCustomer from './KnowYourCustomer.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    KnowYourCustomer,
  },
  data() {
    return {
      options: {
        user: store.state.user.currentUser.user,
      },
      generalData: {
        avatar: require('@/assets/images/avatars/avatar.png'),
        username: 'johndoe',
        fullName: 'John Doe',
        email: 'granger007@hogward.com',
        company: 'Crystal Technologies',
      },
    }
  },
  mounted() {
    // this.$http.get(apiUrl.slice(0,-1)).then(res => { console.log(res.data) })
    this.profile({ token: store.state.user.currentUser.token })
  },
  methods: {
    ...mapActions(['profile']),
  },
}
</script>
