<template>
  <b-card>
    <div>
      <b-overlay :show="showChangePasswordOverlay" rounded="sm" variant="dark">
        <div>
          <!-- form -->
          <b-form
            @submit="onSubmitChangePassword"
            @reset="onResetChangePassword"
          >
            <b-row>
              <!-- old password -->
              <b-col md="6">
                <b-form-group
                  label="Old Password"
                  label-for="account-old-password"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-old-password"
                      v-model="password_old"
                      :type="password_old_type"
                      placeholder="Old Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconOld"
                        class="cursor-pointer"
                        @click="togglePasswordOld"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!--/ old password -->
            </b-row>
            <b-row>
              <!-- new password -->
              <b-col md="6">
                <b-form-group
                  label-for="account-new-password"
                  label="Create New Password"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-new-password"
                      v-model="password"
                      :type="password_type"
                      placeholder="New Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconNew"
                        class="cursor-pointer"
                        @click="togglePasswordNew"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!--/ new password -->

              <!-- retype password -->
              <b-col md="6">
                <b-form-group
                  label-for="account-retype-new-password"
                  label="Confirm New Password"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-retype-new-password"
                      v-model="password_confirmation"
                      :type="password_confirmation_type"
                      placeholder="New Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconRetype"
                        class="cursor-pointer"
                        @click="togglePasswordRetype"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!--/ retype password -->

              <!-- buttons -->
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-1 mr-1"
                  type="submit"
                >
                  Save changes
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  class="mt-1"
                >
                  Reset
                </b-button>
              </b-col>
              <!--/ buttons -->
            </b-row>
          </b-form>
        </div>
      </b-overlay>
    </div>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { apiUrl } from "@/constants/config";
import store from "@/store";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      password_old: "",
      password: "",
      password_confirmation: "",
      password_old_type: "password",
      password_type: "password",
      password_confirmation_type: "password",
      showChangePasswordOverlay: false,
    };
  },
  computed: {
    passwordToggleIconOld() {
      return this.password_old_type === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.password_type === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.password_confirmation_type === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  methods: {
    togglePasswordOld() {
      this.password_old_type =
        this.password_old_type === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.password_type =
        this.password_type === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.password_confirmation_type =
        this.password_confirmation_type === "password" ? "text" : "password";
    },
    onSubmitChangePassword(event) {
      this.showChangePasswordOverlay = true;
      event.preventDefault();
      axios({
        url: apiUrl + `change-password`,
        data: {
          password_old: this.password_old,
          password: this.password,
          password_confirmation: this.password_confirmation,
        },
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + store.state.user.currentUser.token,
        },
      })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success: " + response.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.onResetChangePassword(event);
        })
        .catch((error) => {
          console.log(error.response.data.message);
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                "Error: " +
                (error.response.status == 422
                  ? error.response.data.message
                  : error.message),
              icon: "EditIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.showChangePasswordOverlay = false;
        });
    },
    onResetChangePassword(event) {
      event.preventDefault();
      this.password_old = "";
      this.password = "";
      this.password_confirmation = "";
    },
  },
};
</script>
